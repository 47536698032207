<template>
  <c-box w="full">
    <Breadcrumb :items="breadcrumbItems" />
    <hr v-chakra mt="20px" />
    <CreateForm
      ref="$forms"
      :value="glossary"
      @input="onFormChanged($event)"
      v-chakra
      mt="30px"
    />
    <c-stack mt="30px" is-inline spacing="20px">
      <c-button
        type="button"
        rounded="full"
        w="289px"
        h="62px"
        as="router-link"
        :to="{ name: 'admin.glossary' }"
      >
        Batal
      </c-button>
      <c-button
        type="button"
        rounded="full"
        w="289px"
        h="62px"
        variant-color="brand"
        :is-disabled="isLoading || isInvalid"
        :disabled="isLoading || isInvalid"
        @click.prevent="submit()"
      >
        {{ isLoading ? 'Menyimpan...' : 'Simpan' }}
      </c-button>
    </c-stack>
  </c-box>
</template>

<script>
import CreateForm from "./forms.vue";
import Breadcrumb from "@/components/breadcrumb";
import equal from "fast-deep-equal";

export default {
  name: "SUGlossaryEdit",
  components: { Breadcrumb, CreateForm },
  data() {
    return {
      glossary: null,
      isInvalid: false,
      isLoading: false,
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          label: "Manajemen Glossary",
          href: this.$router.resolve({ name: "admin.glossary" }).href,
        },
        {
          label: "Edit Glossary",
          isCurrent: true,
        },
      ];
    },
    glossaryId() {
      return this.$route.params.glossaryId;
    },
  },
  watch: {
    glossaryId: {
      immediate: true,
      async handler(glossaryId, old) {
        if (equal(glossaryId, old)) return;

        let data = await this.$store.dispatch("admGlossary/detail", glossaryId);

        this.glossary ??= {};
        this.glossary.id = data.id;
        this.glossary.ingredientName = data.ingredientName;
        this.glossary.foodGroup = data.foodGroup;
        this.glossary.foodIngredient = data.foodIngredient;
        this.glossary.dose = data.dose;
        this.glossary.doseUnit = data.doseUnit;
        this.glossary.photoUrl = data.photoUrl;
        this.glossary.fat = data.fat;
        this.glossary.protein = data.protein;
        this.glossary.carbohydrate = data.carbohydrate;
        this.glossary.calories = data.calories;
      },
    },
  },
  methods: {
    onFormChanged($event) {
      this.glossary = $event;
    },
    async submit() {
      if (this.isInvalid) return;

      let { photoFile, ...glossary } = this.glossary;
      glossary.id = this.glossaryId;

      try {
        this.isLoading = true;

        if (photoFile != null) {
          glossary.photoUrl = await this.$store.dispatch(
            "admGlossary/upload",
            photoFile
          );
        }

        await this.$store.dispatch("admGlossary/update", glossary).then(() => {
          this.$router.replace({
            name: "admin.glossary-detail",
            params: { glossaryId: this.glossaryId },
          });
        });
      } catch (e) {
        this.$toast({
          title: "Failed",
          description: e?.data?.message ?? e.toString(),
          status: "error",
          duration: 5000,
          position: "bottom-right",
          variant: "subtle",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  updated() {
    this.isInvalid = this.$refs.$forms?.$v.$invalid;
  },
  async mounted() {},
};
</script>

<style scoped></style>
